import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'SauBol - Интернет магазин'
  const token = store.state.token || localStorage.getItem('uni_token')
  if (to.meta.onlyWhenLoggedOut) {
    return token ? next({ name: from.name || 'layout.main', query: from.query }) : next()
  }
  if (to.meta.requireAuth) {
    return token ? next() : next({ name: 'auth.login', query: { redirect: from.name, ...from.query } })
  }
  next()
})

export default router
