<template>
  <div id="app">
    <router-view />
    <a href="" style="display: none" class="invisibleLink"></a>
  </div>
</template>

<script>
import Api from '@/services/api'

export default {
  name: 'App',
  async beforeMount() {
    const user = JSON.parse(localStorage.getItem('uni_user'))
    if (user) {
      this.$store.commit('SET_USER', user)
      this.$nextTick(async () => {
        const { cart } = await this.wrapAndHandleRequest(Api.fetchCartList, false)
        const { favorites } = await this.wrapAndHandleRequest(Api.fetchFavoriteList, false)
        this.$store.commit('products/SET_FAVORITE_PRODUCTS', favorites)
        this.$store.commit('products/SET_BASKET_PRODUCTS', cart)
      })
    }
  },
}
</script>
<style>
.wrap_e962 {
  bottom: 50px !important;
}
.globalClass_e906 .mobileContainer_db67 {
  bottom: 50px !important;
}
</style>