import axios from '../axios'

export default {
  createOrder: async (data) => axios.post('user/order-auth-create', data),
  addTransaction: async (data) => axios.post('user/add-transaction', data),
  guestAddTransaction: async (data) => axios.post('guest/add-transaction', data),
  SuccessToServ: async (data) => axios.post('postback/cp-widget', data),
  userPay: async () => axios.get('user/pay'),
  guestCreateOrder: async (data) => axios.post('guest/order-create', data),
  guestPay: async (data) => axios.post('guest/pay', data),
  ordersList: async () => axios.get('user/order-list'),
  gerOrderInfo: async (orderNumber) => axios.get(`user/get-order?orderNumber=${orderNumber}`),
}
