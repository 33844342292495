<template>
  <div class="catalog-wrapper">
    <b-skeleton-wrapper :loading="!isCatalogFetched">
      <template #loading>
        <div class="nav__catalog" @click="$router.push('/category/' + categories[0].slug + '/page/1')">
              <span></span>
              Каталог
            </div>
      </template>

      <div class="nav__catalog"  @click="toggleCatalog()">
              <span></span>
              Каталог
            </div>
    </b-skeleton-wrapper>
    

    <transition name="fade">
      <div v-show="isCatalogOpen" class="catalog">
        <ul class="catalog__parent">
          <li
            v-for="item in categories"
            :key="item.id"
            class="catalog__parent-item"
            :class="{ 'catalog__parent-item_active': item.id === activeCategory.id }"
            @mouseover="setActiveCategory(item.id)"
          >
            <router-link class="catalog__link" :to="{ name: 'layout.category', params: { slug: item.slug, page: 1 } }">
              {{ item.name }}
            </router-link>
            
          </li>
        </ul>
        <div class="catalog__child">
          <div class="catalog__child-title mb-4">{{ activeCategory.name }}</div>
          <ul v-if="activeCategory.childs && activeCategory.childs.length" class="catalog__child-list">
            <li v-for="item in activeCategory.childs" :key="item.id" class="catalog__child-item mr-4 mb-3">
              <router-link
                class="catalog__link"
                :to="{ name: 'layout.category', params: { slug: item.slug, page: 1 } }"
              >
                {{ item.name }}
              </router-link>
            </li>
          </ul>
          <p v-else class="empty">Отсутствуют подкатегории</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Api from '@/services/api'

export default {
  name: 'HeaderCatalog',
  data: () => ({
    isCatalogFetched: false,
    isCatalogOpen: false,
    activeCategory: {
      name: '',
      id: 0,
      childs: [],
    },
  }),

  computed: {
    categories() {
      return this.$store.state.categories
    },
  },

  async mounted() {
    if (this.categories.length) {
      this.isCatalogFetched = true
      this.$root.$emit('categories-fetched', true)
      return
    }
    try {
      const { categories } = await this.wrapAndHandleRequest(Api.getCategories, false)
      if (!categories || !Array.isArray(categories)) return
      this.$store.commit('SET_CATEGORIES', categories)
      if (this.categories.length) this.setActiveCategory(categories[0].id)
      await this.sleep(1)
    } finally {
      this.isCatalogFetched = true
      this.$root.$emit('categories-fetched', true)
      this.$root.$on('show-catalog', () => (this.isCatalogOpen = true))
    }
  },

  methods: {
    toggleCatalog() {
      this.isCatalogOpen = !this.isCatalogOpen
    },

    setActiveCategory(id) {
      this.activeCategory = this.categories.find((el) => el.id === id)
    },
  },

  watch: {
    async $route() {
      this.$nextTick(() => (this.isCatalogOpen = false))
    },
  },
}
</script>
