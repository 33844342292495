<template>
  <b-skeleton-wrapper :loading="!isCategoriesFetched">
    <template #loading>
      <b-skeleton height="50px"></b-skeleton>
    </template>
    <!--<ul v-show="items.length" class="top-category mb-0">
      <li v-for="item in items" :key="item.id" class="top-category__item">
        <router-link
          class="top-category__item-link font-weight-light"
          :to="{ name: 'layout.category', params: { slug: item.slug, page: 1 } }"
        >
          {{ item.name }}
        </router-link>
      </li>
    </ul>-->
  </b-skeleton-wrapper>
</template>

<script>
export default {
  name: 'TopCategories',
  data: () => ({
    isCategoriesFetched: false,
  }),

  mounted() {
    this.$root.$on('categories-fetched', this.setCategoriesFetchedValue)
    setTimeout(() => (this.isCategoriesFetched = true), 1000)
  },

  computed: {
    items() {
      return this.$store.state.categories
    },
  },

  methods: {
    setCategoriesFetchedValue(value) {
      this.isCategoriesFetched = value
    },
  },
}
</script>
