import MainLayout from '@/layouts/MainLayout'
import EmptyLayout from '@/layouts/EmptyLayout'

export default [
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '/',
        name: 'layout.main',
        meta: {
          title: 'SauBol - Главная страница',
        },
        component: () => import(/* webpackChunkName: "MainPage" */ '@/views/main/MainPage'),
      },
      {
        path: '/profile',
        name: 'layout.profile',
        meta: {
          requireAuth: true,
          title: 'SauBol - Профиль',
        },
        component: () => import(/* webpackChunkName: "ProfilePage" */ '@/views/profile/ProfilePage'),
      },
      {
        path: '/category/:slug/page/:page',
        name: 'layout.category',
        meta: {
          title: 'SauBol',
        },
        component: () => import(/* webpackChunkName: "CategoryPage" */ '@/views/category/CategoryPage'),
      },
      {
        path: '/product/:slug/',
        name: 'layout.product',
        meta: {
          title: 'SauBol',
        },
        component: () => import(/* webpackChunkName: "ProductPage" */ '@/views/product/ProductPage'),
      },
      {
        path: '/basket',
        name: 'layout.basket',
        meta: {
          requireAuth: true,
          title: 'SauBol - Корзина',
        },
        component: () => import(/* webpackChunkName: "BasketPage" */ '@/views/basket/BasketPage'),
      },
      // {
      //   path: '/no-auth-basket',
      //   name: 'layout.basket.no-auth',
      //   meta: {
      //     title: 'UniMarket - Корзина',
      //     onlyWhenLoggedOut: true,
      //   },
      //   component: () => import(/* webpackChunkName: "NoAuthBasket" */ '@/views/basket/NoAuthBasketPage'),
      // },
      {
        path: '/faq',
        name: 'layout.faq',
        meta: {
          title: 'SauBol - FAQ',
        },
        component: () => import(/* webpackChunkName: "Faq" */ '@/views/faq/FaqPage'),
      },
      {
        path: '/about-us',
        name: 'layout.faq',
        meta: {
          title: 'SauBol - FAQ',
        },
        component: () => import(/* webpackChunkName: "Faq" */ '@/views/about/About'),
      },
      {
        path: '/favorites',
        name: 'layout.favorites',
        meta: {
          title: 'SauBol - Избранные',
        },
        component: () => import(/* webpackChunkName: "Favorites" */ '@/views/favorites/FavoritesPage'),
      },
      {
        path: '/orders',
        name: 'layout.orders',
        meta: {
          requireAuth: true,
          title: 'SauBol - Мои заказы',
        },
        component: () => import(/* webpackChunkName: "Orders" */ '@/views/orders/MyOrdersPage'),
      },
      {
        path: '/order/:id',
        name: 'layout.order.details',
        meta: {
          requireAuth: true,
          title: 'SauBol - Детали заказа',
        },
        component: () => import(/* webpackChunkName: "OrderDetails" */ '@/views/orders/OrderDetails'),
      },
      {
        path: '/payment-success',
        name: 'layout.payment.success',
        meta: {
          title: 'SauBol - Успешная оплата',
        },
        component: () => import(/* webpackChunkName: "PaymentSuccess" */ '@/views/payment-status/PaymentSuccessPage'),
      },
      {
        path: '/payment-fail',
        name: 'layout.payment.fail',
        meta: {
          title: 'SauBol - Оплата не была произведена',
        },
        component: () => import(/* webpackChunkName: "PaymentFail" */ '@/views/payment-status/PaymentFailPage'),
      },
    ],
  },

  {
    path: '/auth',
    component: EmptyLayout,
    meta: {
      onlyWhenLoggedOut: true,
    },
    children: [
      {
        path: '',
        name: 'auth.login',
        component: () => import(/* webpackChunkName: "AuthLogin" */ '@/views/auth/AuthPage'),
        meta: {
          onlyWhenLoggedOut: true,
          title: 'Sau-bol - Авторизация',
        },
      },
      {
        path: '/registration',
        name: 'auth.registration',
        component: () => import(/* webpackChunkName: "AuthRegister" */ '@/views/auth/RegistrationPage'),
        meta: {
          onlyWhenLoggedOut: true,
          title: 'Sau-bol - Регистрация',
        },
      },
      {
        path: '/forgot-password',
        name: 'auth.forgot',
        component: () => import(/* webpackChunkName: "ForgotPassword" */ '@/views/auth/ForgotPasswordPage'),
        meta: {
          onlyWhenLoggedOut: true,
          title: 'Sau-bol - Забыл пароль',
        },
      },
    ],
  },
]
